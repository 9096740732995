<template>
  <div class="courier-list">
    <div class="is-flex is-justify-content-flex-end">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="createCourier">
        Добавить курьера
      </el-button>
    </div>
    <el-table :data="data" @row-click="goToClient">
      <el-table-column prop="id" label="#" width="80" />
      <el-table-column prop="name" label="Название" />
      <el-table-column prop="comment" label="Кол-во заказов">
        <template slot-scope="{ row: { orders } }">
          {{ orders.filter((o) => !o.isDelivered).length }}
        </template>
      </el-table-column>
      <el-table-column align="right" width="180">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click.stop="deleteCourier(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination is-flex is-justify-content-center mt-5">
      <el-pagination
        class=""
        background
        layout="prev, pager, next"
        :current-page="meta.current_page"
        :page-size="meta.per_page"
        :page-count="meta.last_page"
        @current-change="changePage"
      />
    </div>
    <documents-preview ref="documentsPreviewRef" />
    <courier-create ref="createCourierRef" />
  </div>
</template>
<script>
import store from '@/app/app-state';
import documentsPreview from '@/app/documents/documents-preview.vue';
import { COURIER_STORE_MODULE_NAME, courierStoreModule } from './courierStoreModule';
import { useCourierList } from './useCourierList';
import CourierCreate from './courier-create/courier-create.vue';

export default {
  components: { documentsPreview, CourierCreate },
  setup() {
    if (!store.hasModule(COURIER_STORE_MODULE_NAME)) {
      store.registerModule(COURIER_STORE_MODULE_NAME, courierStoreModule);
    }
    const {
      meta,
      data,
      changePage,
      deleteCourier,
      updateCourierRef,
      updateCourier,
      createCourier,
      createCourierRef,
      generateFile,
      documentsPreviewRef,
      previewFileTable,
      goToClient,
    } = useCourierList();

    return {
      meta,
      data,
      changePage,
      deleteCourier,
      updateCourierRef,
      updateCourier,
      createCourier,
      createCourierRef,
      generateFile,
      documentsPreviewRef,
      previewFileTable,
      goToClient,
    };
  },
};
</script>
